body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
}

header {
  color: #fff;
  background-color: #33507b;
  padding: 33px 0 25px 15px;
}

header h1 {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0.3px;
  display: inline-block;
  margin: 0;
  vertical-align: middle;
  
}

.fa {
  font-size: 22px;
  transform: translateY(50deg);  
}

header i {
  vertical-align: middle;
  margin-left: 12px;
}

.container {
  display: flex;
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 30px;
  max-height: 100%;
}

.wrapper {
  max-width: 1100px;
  margin: 0 auto;
}

.add-item {
  border-radius: 2px;
  border-top: 3px solid #33507b;
  margin-right: 50px;
  background-color: rgb(237, 237, 237);
  padding: 60px 18px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12), 0 1px 8px 0 rgba(0,0,0,.2);
  margin-bottom: 34px;  
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.display-item {
  width: 65%;
  margin-left: 50px;
}

.display-item button {
  margin-top: 20px;
  margin-left: auto;
  display: block;
}

.add-item form {
  width: 100%;
  overflow: hidden;
}

input {
  font-size: 22px;
  color: #000;
  padding: 10px 10px;
  font-size: 16px;
  margin: 17px 0px 17px 0px;
  border: 0;
  display: block;
  width: 100%;  
}

h2 {
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-weight: bold;
}

form button {
  width: 100%;
  margin-top: 10px;
}

button {
  border-radius: 2px;
  min-width: 15px;
  background-color: #FA6900;
  cursor: pointer;
  border: 0;
  min-width: 120px;
  color: #fff;
  font-size: 14px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
  padding: 10px 6px;
  margin: 17px 0px 17px 0px;
}

.option{
  min-width: 20px;
  margin: 3vh 3px 3px 3px;
  outline: none; 
}

.sound{
  border-radius: 2px;
  border-top: 3px solid #33507b;
  margin-right: 50px;
  background-color: rgb(237, 237, 237);
  padding: 60px 18px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12), 0 1px 8px 0 rgba(0,0,0,.2);
  margin-bottom: 34px;  
  width: 25%;
  height: 20vh;
  align-content: center;
}

.leaderboard{
  width: 30%;
  border-radius: 2px;
  border-top: 3px solid #33507b;
  margin-right: 50px;
  background-color: rgb(237, 237, 237);
  padding: 60px 18px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12), 0 1px 8px 0 rgba(0,0,0,.2);
  margin-bottom: 34px;  
  height: 35vh;
  align-content: center;
}

.list{
  height: 30vh;
  width: 90%;
  overflow-y: auto;
  position:relative;
  background-color: #fff;
}

.list li{
  width: 100%;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}

.list li h3{
  flex: 1;
}

.list li .score{
  flex: 0;
  margin-right: 20%;
}

.list li p{
  margin-right: 20%;
}

.leaderboard h2{
  text-align: center;
  font-weight: bold;
}