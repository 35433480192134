.soundcontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #fff;
}